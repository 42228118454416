import React, { Component } from 'react';
import { Admin, CustomRoutes, Loading, Resource, withLifecycleCallbacks } from 'react-admin';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';
import { createUploadLink } from 'apollo-upload-client';
import buildGraphQLProvider, { buildQuery } from 'ra-data-graphql-simple';
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { setContext } from '@apollo/client/link/context';
import AdminLayout from 'components/theme/AdminLayout';
import customTheme from 'components/theme/customTheme';
import resources from 'resources';
import { getToken, isAuthenticated } from 'utils/auth';
import { formatLogoUploadFile, getUploadFileArray } from 'utils/dataProvider';
import authProvider from './authProvider';
import BulkUserUpdate from './pages/BulkUserUpdate';
import { ALL_ADMIN_CUSTOM_PAGES, ALL_ADMIN_MODULES, ALL_ADMIN_EVENTS } from './graphql';

const cache = new InMemoryCache();

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_API_URL,
  headers: {
    'Apollo-Require-Preflight': 'true',
  },
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: isAuthenticated() ? `Bearer ${getToken()}` : '',
    },
  };
});

const removeTypenameLink = removeTypenameFromVariables();

export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([removeTypenameLink, authLink, httpLink]),
});


const customQueryMap = {
  GET_MANY: {
    AdminCustomPage: ALL_ADMIN_CUSTOM_PAGES,
    AdminModule: ALL_ADMIN_MODULES,
    AdminEvent: ALL_ADMIN_EVENTS,
  },
  GET_LIST: {
    AdminCustomPage: ALL_ADMIN_CUSTOM_PAGES,
    AdminModule: ALL_ADMIN_MODULES,
    AdminEvent: ALL_ADMIN_EVENTS,
  },
}

/*
 * Custom query builder passed to buildGraphqlProvider
 * This allows us to restrict the fields requested in specific queries
 * https://www.npmjs.com/package/ra-data-graphql-simple#overriding-a-specific-query
 */
const customQueryBuilder = (introspection) => (fetchType, resource, params) => {
  const builtQuery = buildQuery(introspection)(fetchType, resource, params);

  if (customQueryMap[fetchType]?.[resource]) {
    return {
      ...builtQuery,
      query: customQueryMap[fetchType][resource]
    }
  }

  return builtQuery;
}

/**
 * @param data
 * @param field - The name of the field in the admin
 * @param source - The name of the graphql input
 * @returns {Promise<void>}
 */
async function transformImageFields(data, field = 'badgeImage', source = 'image') {
  if (data[field]?.rawFile) {
    data.badge[source] = await formatLogoUploadFile(data[field]);
    delete data[field];
  } else if (data.badge?.[source].rawFile) {
    data.badge[source] = await formatLogoUploadFile(data.badge[source]);
  } else if (data?.[field].id) {
    data.badge[source] = { documentId: data[field].id };
    delete data[field];
  }

  if (data.badge[`${source}.id`]) {
    delete data.badge[`${source}.id`];
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { dataProvider: null };
  }

  /**
   * Data Provider job is to turns these method calls into HTTP requests, and
   * Transform the HTTP responses to the data format expected by the react-admin.
   *
   * Make CRUD requests to the back-end API (server) and
   * Turns the results to react-admin expected results.
   * */
  componentDidMount() {
    buildGraphQLProvider({
      client,
      buildQuery: customQueryBuilder,
    }).then(gqlProvider => {
      const dataProvider = withLifecycleCallbacks(gqlProvider, [
        {
          resource: 'AdminModule',
          beforeSave: async (data) => {
            if (data.attachments && data.attachments.length > 0) {
              data.attachments = await getUploadFileArray(data.attachments);
            }
            return data;
          },
        },
        {
          resource: 'AdminBadgeTemplate',
          beforeSave: async (data) => {
            await transformImageFields(data, 'badgeImage', 'image');
            await transformImageFields(data, 'badgeShareImage', 'shareImage');

            return data;
          },
        },
        {
          resource: 'AdminEvent',
          beforeSave: async (data) => {
            if (data.attachments && data.attachments.length > 0) {
              data.attachments = await getUploadFileArray(data.attachments);
            }
            return data;
          },
        },
        {
          resource: 'AdminCustomPage',
          beforeSave: async (data) => {
            if (data.attachments && data.attachments.length > 0) {
              data.attachments = await getUploadFileArray(data.attachments);
            }
            return data;
          },
        },
        {
          resource: 'AdminAgency',
          beforeSave: async (data) => {
            if (data.logoUpload) {
              data.logoUpload = await formatLogoUploadFile(data.logoUpload);
            }
            delete data.attachment;
            return data;
          },
        },
      ]);
      this.setState({ dataProvider });
    });
  }

  render() {
    const { dataProvider } = this.state;

    if (!dataProvider) {
      return <Loading />;
    }

    return (
      <>
        <Helmet>
          {process.env.REACT_APP_GOOGLE_SITE_VERIFICATION_KEY && <meta name="google-site-verification" content={process.env.REACT_APP_GOOGLE_SITE_VERIFICATION_KEY} />}
        </Helmet>
          <Admin
            requireAuth
            title='Infinitec Admin'
            layout={AdminLayout}
            dataProvider={dataProvider}
            authProvider={authProvider}
            theme={customTheme}
          >
            <>
              <CustomRoutes>
                <Route path='/emailUpdate' element={<BulkUserUpdate />} />
              </CustomRoutes>
              {resources}
            </>
          </Admin>
      </>
    );
  }
}

export default App;
